import Popover from "react-bootstrap/Popover";
import React from "react";

export const popoverAccountAdmin = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Account Admin Info"
    >
        Set user as an Account Admin.<br/>
        User will be granted the following permissions on the account<br/>
        Manage Account/Location Users<br/>
        All existing and new locations will be auto added to the users account<br/>
    </Popover>
);
export const popoverAutoAddLocations = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Auto Add Location Info"
    >
        Automatically grant the user access to new locations which are attached to the account.
    </Popover>
);
export const popoverAutoAddAlerts = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Auto Add Alerts"
    >
        Automatically add user to Alert subscriptions for new locations..
    </Popover>
);
export const popoverLocationAdmin = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Location Admin Info"
    >
        Set user as an Location Admin.<br/>
        User can manage users for this location.
    </Popover>
);
export const popoverLocationAlerts = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Alerts Info"
    >
        Subscribe user to Alerts.
    </Popover>
);
export const popoverAccountActive = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Account Active"
    >
        Enable/Disable this users account.
    </Popover>
);
export const popoverAccountContact = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Account Contact Info"
    >
        Set user as an Account Contact.<br/>
    </Popover>
);
export const popoverEmergencyContact = (
    <Popover
        id="popover-trigger-hover-focus"
        title="Emergency Contact for this location"
    >
        Set user as an Account Contact.<br/>
    </Popover>
);

export const popoverAccountInvoices = (
  <Popover
    id="popover-trigger-hover-focus"
    title="Account Invoices"
  >
      Allow user to generate invoices for this account.
  </Popover>
);