import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import moment from 'moment-timezone'
import { FaBan } from 'react-icons/fa'

import Device, { TestDevice } from '@context/device/model/device'
import {
  hasPermissions,
  sortObjectsByKey,
  getBatteryPercentage,
  getBatteryIconClass,
  getSignalStatus,
  getBatteryStatus,
  getDeviceStatus,
} from '@common/utils/helperFunctions'
import { useAuthState } from '@context/auth/context/auth.context'
import { COLUMNS, DEVICE_CONNECTION_STATUS_OPTIONS } from './constants'
import SectorIcon from '../../../general/SectorIcon/SectorIcon'
import './DeviceStatus.scss'

interface IDeviceStatusProps {
  devices: Device[] | TestDevice[]
}

const DeviceStatus: React.FC<IDeviceStatusProps> = ({ devices }: IDeviceStatusProps) => {
  const { permissions } = useAuthState()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<string>('all')
  const [sortingConf, setSortingConf] = useState<{ key: string; order: string }>({
    key: '',
    order: '',
  })
  const [filteredDevices_one, setFilteredDevices_one] = useState<Device[] | TestDevice[]>([])
  const [filteredDevices_two, setFilteredDevices_two] = useState<Device[] | TestDevice[]>([])
  const [sortedDevices, setSortedDevices] = useState<Device[] | TestDevice[]>(filteredDevices_two)

  const onSearchDeviceTermChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value)
  }

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  const clearSortingConfig = () => {
    setSortingConf({ key: '', order: '' })
  }

  const onColumnHeaderClick = (columnKey: string) => {
    let order: string
    if (sortingConf.key === columnKey) {
      if (sortingConf.order === '') {
        order = 'ASC'
      } else if (sortingConf.order === 'ASC') {
        order = 'DESC'
      } else {
        order = ''
      }
    } else {
      order = 'ASC'
    }
    setSortingConf({ key: columnKey, order })
  }

  const onDeviceStatusFilterClick = (filter: any) => {
    clearSearchTerm()
    clearSortingConfig()
    setStatusFilter(filter.key)
  }

  const getSortingClass = (columnKey: string) => {
    return sortingConf.key === columnKey && sortingConf.order !== ''
      ? `fas fa-arrow-${sortingConf.order === 'ASC' ? 'down' : 'up'}`
      : ''
  }

  const getRowClassName = (deviceStatus: Device | TestDevice) => {
    let className: string = ''
    if (deviceStatus.deviceSettings?.uploadFreqMins) {
      if (!deviceStatus.lastOnline) {
        className = 'device-disconnected'
      } else {
        const interval = (parseInt(deviceStatus.deviceSettings?.uploadFreqMins) / 60) * 2
        const m = moment.utc()
        const u = m.subtract(interval, 'h')
        if (moment(deviceStatus.lastOnline) < u) {
          className = 'device-disconnected'
        }
      }
    }

    return className
  }

  useEffect(() => {
    setFilteredDevices_one(devices)
  }, [devices])

  useEffect(() => {
    let filtered = devices
    if (statusFilter !== 'all') {
      filtered = devices.filter(
        (device) => device.connectionStatus.toLowerCase() === statusFilter.toLowerCase(),
      )
    }
    setFilteredDevices_one(filtered)
  }, [statusFilter, devices])

  useEffect(() => {
    let filtered = filteredDevices_one
    if (searchTerm !== '') {
      filtered = filteredDevices_one.filter(
        (device) =>
          device.deviceName.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          String(device.deviceId).includes(searchTerm.trim().toLowerCase()),
      )
    }
    setFilteredDevices_two(filtered)
  }, [filteredDevices_one, searchTerm])

  useEffect(() => {
    let sorted = []
    if (sortingConf.order !== '') {
      const arr2 = [...filteredDevices_two]
      sorted = sortObjectsByKey(arr2, sortingConf.key, sortingConf.order)
      setSortedDevices(sorted)
    } else {
      setSortedDevices(filteredDevices_two)
    }
  }, [filteredDevices_two, sortingConf])

  const deviceStatusFilter = DEVICE_CONNECTION_STATUS_OPTIONS.map(
    (filter: { label: string; key: string }, idx: number) => (
      <span
        key={idx}
        className={`nav-link ${
          filter.key.toLowerCase() === statusFilter.toLowerCase() ? 'active' : 'inactive'
        }`}
        onClick={() => onDeviceStatusFilterClick(filter)}
      >
        {filter.label}
      </span>
    ),
  )

  function renderDeviceStatus(device: Device | TestDevice) {
    const deviceStatus = getDeviceStatus(
      device,
      hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW']),
    )

    if (deviceStatus === 'Offline' && hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])) {
      return (
        <>
          Offline
          <small style={{ fontSize: '0.7em' }}>{` (last seen: ${moment(
            device.lastOnline,
          ).fromNow()})`}</small>
        </>
      )
    }

    return deviceStatus
  }

  const headers = COLUMNS.map((col: { name: string; key: string }, idx: number) => (
    <th
      className="clickable"
      style={{ width: '15%', position: 'sticky', top: 0, zIndex: 2 }}
      key={idx}
      onClick={() => onColumnHeaderClick(col.key)}
    >
      {col.name}
      <i className={getSortingClass(col.key)}></i>
    </th>
  ))

  const rows = sortedDevices.map((device: Device | TestDevice, idx: number) => {
    const batteryPercentage = getBatteryPercentage(device.batteryLevel, device.deviceVendor)
    const batteryPercentageExt = getBatteryPercentage(device.batteryLevelExt, device.deviceVendor)
    const rowClassName = getRowClassName(device)
    return (
      <tr key={idx} className={rowClassName}>
        <td>
          {device?.deviceSettings?.sectorType &&
            (hasPermissions(permissions, ['UPDATE:ACCOUNT']) ? (
              <SectorIcon
                sector={device.deviceSettings.sectorType}
                occupants={device.deviceSettings.occupants}
              />
            ) : null)}{' '}
          {device.deviceName}
        </td>
        <td> {device.deviceId} </td>
        <td>
          {device.batteryLevel ? (
            <>
              <div className="battery-container">
                <span className={getBatteryIconClass(batteryPercentage)} />
                <span className="battery-level"> {batteryPercentage}% (Int)</span>
              </div>
              {device.batteryLevelExt ? (
                <div className="battery-container">
                  <span className={getBatteryIconClass(batteryPercentageExt)} />
                  <span className="battery-level"> {batteryPercentageExt}% (Ext)</span>
                </div>
              ) : null}
            </>
          ) : (
            <div className="battery-container">
              <FaBan />
            </div>
          )}
        </td>
        <td>
          <div className="signal-container">
            <span>{device.signal ? getSignalStatus(device.signal) : <FaBan />}</span>
            {/*<span>{device.signal ? `${Math.round((device.signal / 31) * 100)}%` : null}</span>*/}
            {/*<span>{device.signal ? device.signal : null}</span>*/}
          </div>
        </td>
        {/*<td>*/}
        {/*  <div className="csq-container">*/}
        {/*    <span>{device.signal ? device.signal : <FaBan />}</span>*/}
        {/*  </div>*/}
        {/*</td>*/}
        <td>
          <div className="power-container">
            {device.batteryStatus ? getBatteryStatus(device) : <FaBan />}
          </div>
        </td>
        <td>
          {device.deviceVendor === 'SMARTFLOW' ||
          device.deviceVendor === 'METASPHERE' ||
          device.deviceVendor === 'PLUM' ? (
            renderDeviceStatus(device)
          ) : (
            <FaBan />
          )}
        </td>
        <td>{device.deviceSettings?.active ? 'Active' : 'Inactive'}</td>
        <td>{device.deviceVendorName}</td>
      </tr>
    )
  })

  return (
    <div className="device-status">
      <h1>Device Status</h1>
      <div className="container-fluid flex-container">
        <div className="status-filter-container">
          <nav className="nav nav-pills nav-justified">{deviceStatusFilter}</nav>
        </div>
        <div className="search-section">
          <div className="form-group search-bar">
            <span className="fa fa-search search-icon"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search by name or device id"
              value={searchTerm}
              onChange={onSearchDeviceTermChange}
            />
            <span className="fa fa-times clear-icon" onClick={clearSearchTerm}></span>
          </div>
          <div className="result-number">
            <span>( {filteredDevices_two.length} )</span>
          </div>
        </div>
        <Table>
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </div>
  )
}

export default DeviceStatus
