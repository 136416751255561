import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment/moment'
import { SMARTFLOW_FOOTER_IMAGE, smartflowHeaderImg } from '@common/utils/constants'
import Device from '@context/device/model/device'

export const buildPDFReport = (
  accountName: string,
  fromDate: Date | null,
  toDate: Date | null,
  totalChartImage: string,
  top5ChartImage: string,
  tableElement: HTMLTableElement,
): Promise<jsPDF> => {
  return new Promise((res) => {
    const from = moment(fromDate).format('MMMM Do YYYY')
    const to = moment(toDate).format('MMMM Do YYYY')
    const pdf = new jsPDF('portrait')

    const pdfWidth = pdf.internal.pageSize.getWidth()
    const headerPosX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
    // const headerImgProps = pdf.getImageProperties(smartflowHeaderImg)
    // const headerRatio = pdfWidth / headerImgProps.width

    // const footerImgProps = pdf.getImageProperties(SMARTFLOW_FOOTER_IMAGE)

    let posXStart = 5
    let posYStart = 65

    // Add Chart
    if (totalChartImage && top5ChartImage) {
      let posY = posYStart
      let posX = posXStart

      pdf.setFont('Nunito', 'bold')
      pdf.setTextColor(25, 106, 154)
      pdf.setFontSize(16)
      pdf.text('Alert History', pdf.internal.pageSize.getWidth() / 2, posY, { align: 'center' })

      posY += 15

      pdf.setTextColor(0, 0, 0)
      pdf.setFontSize(14)
      pdf.text(accountName, 10, posY)

      if (fromDate && toDate) {
        posY += 10
        pdf.setFontSize(10)

        pdf.text(`${from} to ${to}`, 10, posY)
      }

      posY += 25
      pdf.addImage(totalChartImage, 'PNG', posX, posY, 110, 60, undefined, 'FAST')
      pdf.addImage(top5ChartImage, 'PNG', posX + 90, posY, 110, 60, undefined, 'FAST')
    }

    // Add Table
    if (tableElement) {
      pdf.addPage()
      autoTable(pdf, {
        html: tableElement,
        startY: posYStart,
        rowPageBreak: 'auto',
        tableWidth: 'wrap',
        tableLineColor: [25, 106, 154],
        styles: {
          fontSize: 10,
        },
        margin: { left: 25, right: 25 },
        columnStyles: {
          0: { cellWidth: 'wrap' },
          1: { cellWidth: 'wrap' },
          2: { cellWidth: 'wrap' },
          3: { cellWidth: 'wrap' },
          4: { cellWidth: 'wrap' },
          5: { cellWidth: 'wrap' },
          6: { cellWidth: 'wrap' },
          7: { cellWidth: 'wrap' },
          8: { cellWidth: 'wrap' },
          9: { cellWidth: 'wrap' },
          10: { cellWidth: 'wrap' },
          11: { cellWidth: 'wrap' },
          12: { cellWidth: 'wrap' },
          13: { cellWidth: 'wrap' },
          14: { cellWidth: 'wrap' },
          15: { cellWidth: 'wrap' },
          16: { cellWidth: 'wrap' },
          17: { cellWidth: 'wrap' },
          18: { cellWidth: 'wrap' },
          19: { cellWidth: 'wrap' },
          20: { cellWidth: 'wrap' },
          21: { cellWidth: 'wrap' },
          22: { cellWidth: 'wrap' },
          23: { cellWidth: 'wrap' },
          24: { cellWidth: 'wrap' },
          25: { cellWidth: 'wrap' },
        },
      })
    }

    const pageCount = pdf.internal.pages.length // With first element null
    const realPageCount = pageCount - 1
    for (let i = 1; i <= realPageCount; i++) {
      pdf.setPage(i)
      // Header
      pdf.addImage(smartflowHeaderImg, 'png', headerPosX, 0, pdfWidth, 55, undefined, 'FAST')

      // const footerHeight = footerImgProps.height * headerRatio

      //Footer
      pdf.addImage(
        SMARTFLOW_FOOTER_IMAGE,
        'png',
        headerPosX,
        pdf.internal.pageSize.getHeight() - 40,
        pdfWidth,
        50,
        undefined,
        'FAST',
      )

      pdf.setFontSize(8)
      pdf.setTextColor(0, 0, 0)
      const footerMsg = `Page ${i} of ${realPageCount}`
      const footerMsgPosY = pdf.internal.pageSize.getHeight() - 35
      pdf.text(footerMsg, 5, footerMsgPosY)
    }

    res(pdf)
  })
}

export const buildAlertPDF = (
  chartElement: HTMLElement,
  device: Device,
  alertTier: number,
  startAlertTime: string,
  endAlertTime: string,
  mostRecentAlert: string,
  alertDuration: string,
  alertClosed: string,
): Promise<jsPDF> => {
  return new Promise((res) => {
    if (chartElement) {
      const pdf = new jsPDF('portrait')

      const pdfWidth = pdf.internal.pageSize.getWidth()
      const headerPosX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
      // const headerImgProps = pdf.getImageProperties(smartflowHeaderImg)
      // const headerRatio = pdfWidth / headerImgProps.width
      // const footerImgProps = pdf.getImageProperties(SMARTFLOW_FOOTER_IMAGE)

      const posXStart = 5
      let posY = 65
      let posX = posXStart

      pdf.setFontSize(12)
      pdf.setTextColor(0, 0, 0)

      pdf.text(device.deviceName, posX, posY)
      posX = posX + pdfWidth - 60
      pdf.setFontSize(14)
      pdf.setTextColor(255, 0, 0)
      if (alertTier) {
        pdf.text(`Tier ${alertTier}`, posX, posY)
      }
      posX = posXStart
      posY = posY + 5
      pdf.setFontSize(12)
      pdf.setTextColor(0, 0, 0)
      pdf.text(device.deviceId, posX, posY)
      posY = posY + 10
      pdf.text(`${startAlertTime} to ${endAlertTime}`, posX, posY)
      posY = posY + 5
      pdf.text(`${mostRecentAlert}`, posX, posY)
      posY = posY + 5
      pdf.text(`${alertDuration} `, posX, posY)
      posY = posY + 10
      pdf.text(`${alertClosed}`, posX, posY)

      posY = posY + 30

      const chartImgData = (chartElement.firstElementChild as HTMLCanvasElement).toDataURL(
        'image/png',
      )

      const imgProps = pdf.getImageProperties(chartImgData)
      const ratio = pdfWidth / imgProps.width
      pdf.addImage(
        chartImgData,
        'PNG',
        posX,
        posY - 2,
        pdfWidth - posX * 2,
        imgProps.height * ratio,
      )

      const pageCount = pdf.internal.pages.length // With first element null
      const realPageCount = pageCount - 1
      for (let i = 1; i <= realPageCount; i++) {
        pdf.setPage(i)
        // Header
        pdf.addImage(smartflowHeaderImg, 'png', headerPosX, 0, pdfWidth, 55, undefined, 'FAST')

        // const footerHeight = footerImgProps.height * headerRatio

        //Footer
        pdf.addImage(
          SMARTFLOW_FOOTER_IMAGE,
          'png',
          headerPosX,
          pdf.internal.pageSize.getHeight() - 40,
          pdfWidth,
          50,
          undefined,
          'FAST',
        )

        pdf.setFontSize(8)
        pdf.setTextColor(0, 0, 0)
        const footerMsg = `Page ${i} of ${realPageCount}`
        const footerMsgPosY = pdf.internal.pageSize.getHeight() - 35
        pdf.text(footerMsg, 5, footerMsgPosY)
      }

      res(pdf)
    }
  })
}

export const COLUMNS = [
  {
    name: 'Flow Date',
    key: 'flow_date',
  },
  {
    name: 'Flow',
    key: 'flow',
  },
  {
    name: 'Score',
    key: 'score',
  },
  {
    name: 'Created',
    key: 'created',
  },
  {
    name: 'Client Action',
    key: 'client_action',
  },
  {
    name: 'Client Action Date',
    key: 'client_action_date',
  },
  {
    name: 'User',
    key: 'user',
  },
]
