import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import Account from '@context/account/model/Account'
import CustomerLocation from '@context/account/model/CustomerLocation'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'
import DatePicker from 'react-datepicker'
import { COUNTRY_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'
import { popoverActive, popoverSuspended, popoverOnboarded } from './Popovers'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Info } from 'lucide-react'
import moment from 'moment'

import './Modal.scss'

interface IModalProps {
  show: boolean
  account: Account
  data: CustomerLocation
  onHide: () => void
}

const LocationEditModal: React.FC<IModalProps> = ({ show, account, data, onHide }: IModalProps) => {
  const [newLocationName, setNewLocationName] = useState('')
  const [newAddressLine1, setNewAddressLine1] = useState('')
  const [newAddressLine2, setNewAddressLine2] = useState('')
  const [newTown, setNewTown] = useState('')
  const [newPostcode, setNewPostcode] = useState('')
  const [newCounty, setNewCounty] = useState('')
  const [newCountry, setNewCountry] = useState('')
  const [newCompanyAlias, setNewCompanyAlias] = useState('')
  const [newIsActive, setNewIsActive] = useState(true)
  const [newIsSuspended, setNewIsSuspended] = useState(false)
  const [newOnboardingStatus, setNewOnboardingStatus] = useState(false)
  const [newOnboardingDate, setNewOnboardingDate] = useState<Date | null>(null)

  const { addLocationToAccount } = useCustomerManagerDispatch()

  const required = !newCountry || newLocationName.length === 0

  useEffect(() => {
    if (show && Object.keys(data).length > 0) {
      setNewLocationName(data?.name)
      setNewAddressLine1(data?.addressLine1 || '')
      setNewAddressLine2(data?.addressLine2 || '')
      setNewTown(data?.town || '')
      setNewPostcode(data?.postcode || '')
      setNewCounty(data?.county || '')
      setNewCountry(data?.country || '')
      setNewCompanyAlias(data?.companyAlias || '')
      setNewIsActive(data?.active)
      setNewIsSuspended(data?.suspended)
      setNewOnboardingStatus(data?.settings?.onboardingStatus)
    } else {
      setNewLocationName('')
      setNewAddressLine1('')
      setNewAddressLine2('')
      setNewTown('')
      setNewPostcode('')
      setNewCounty('')
      setNewCountry('')
      setNewCompanyAlias('')
      setNewIsActive(true)
      setNewIsSuspended(false)
      setNewOnboardingStatus(data?.settings?.onboardingStatus || false)
      if (data?.settings?.onboardingDate) {
        setNewOnboardingDate(moment(data?.settings?.onboardingDate).toDate())
      } else {
        setNewOnboardingDate(null)
      }
    }
  }, [show])

  function handleSave() {
    const updated = {
      id: data.id,
      addressLine1: newAddressLine1,
      addressLine2: newAddressLine2,
      town: newTown,
      postcode: newPostcode,
      county: newCounty,
      country: newCountry,
      name: newLocationName,
      companyAlias: newCompanyAlias,
      active: newIsActive,
      suspended: newIsSuspended,
      settings: {
        onboardingStatus: newOnboardingStatus,
        onboardingDate: newOnboardingStatus ? newOnboardingDate : null,
      },
    }

    addLocationToAccount(updated, account)
    onHide()
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data && (Object.keys(data).length > 0 ? 'Edit Location' : 'Add New Location to Account')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {required ? <span className="red">* Required!!</span> : ''}
        <Form className="location-information-form">
          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>
                Name <span className="red">{newLocationName.length === 0 ? '*' : ''}</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a name"
                value={newLocationName}
                onChange={(e) => setNewLocationName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            { data.id ? <Form.Group as={Col} controlId="formGridLocationId">
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                value={data.id}
                disabled
              />
            </Form.Group> : null}
            <Form.Group as={Col} controlId="formLocationOpts">
              <Form.Label>Location Options</Form.Label>
              <div>
                <Form.Switch
                  type='checkbox'
                  inline
                  onChange={() => setNewIsActive(!newIsActive)}
                  checked={newIsActive}
                  label={
                    <>
                      Active
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverActive}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="locationAcive"
                  id="inline-location-active"
                />

                {data && Object.keys(data).length > 0 && (
                  <Form.Switch
                    type='checkbox'
                    inline
                    onChange={() => setNewIsSuspended(!newIsSuspended)}
                    checked={newIsSuspended}
                    label={
                      <>
                        Suspended
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          trigger={['hover', 'focus']}
                          overlay={popoverSuspended}
                          placement="bottom"
                        >
                          <Info size={15} className={'ml-1 text-info'} />
                        </OverlayTrigger>
                      </>
                    }
                    name="locationSuspended"
                    id="inline-location-suspended"
                  />
                )}
              </div>
            </Form.Group>
          </Row>
          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridCompanyAlias">
              <Form.Label>Sub Entity</Form.Label>
              <Form.Control
                type="text"
                placeholder="ABC Management"
                value={newCompanyAlias}
                onChange={(e) => setNewCompanyAlias(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridAddressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g.   1234 Main St"
                value={newAddressLine1}
                onChange={(e) => setNewAddressLine1(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridAddressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Apartment, studio, or floor"
                value={newAddressLine2}
                onChange={(e) => setNewAddressLine2(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridTown">
              <Form.Label>Town</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin 9  "
                value={newTown}
                onChange={(e) => setNewTown(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPostCode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. D09 P5X4  "
                value={newPostcode}
                onChange={(e) => setNewPostcode(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridCounty">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin"
                value={newCounty}
                onChange={(e) => setNewCounty(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>
                Country <span className="red">{!newCountry ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: newCountry,
                  label: newCountry,
                }}
                onChange={(d: any) => setNewCountry(d.value)}
                options={COUNTRY_OPTIONS.map((country) => {
                  return { value: country, label: country }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4 fw-bold'}>
            <Form.Group as={Col} controlId="formGridOnboardStatus">
              <Form.Switch
                type='checkbox'
                inline
                onChange={() => setNewOnboardingStatus(!newOnboardingStatus)}
                checked={newOnboardingStatus}
                label={
                  <>
                    Onboarded
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      trigger={['hover', 'focus']}
                      overlay={popoverOnboarded}
                      placement="bottom"
                    >
                      <Info size={15} className={'ml-1 text-info'} />
                    </OverlayTrigger>
                  </>
                }
                name="locationAcive"
                id="inline-omboarding_status"
              />
            </Form.Group>

            {!newOnboardingStatus ? (
              <Form.Group as={Col} controlId="formGridOnboardDate">
                <Form.Label>
                  Onboard Date{' '}
                  <span className="red">
                    {newOnboardingStatus && !newOnboardingDate ? '*' : ''}
                  </span>
                </Form.Label>
                <div className="w-100">
                  <DatePicker
                    selected={newOnboardingDate}
                    onChange={(date: Date | null) => setNewOnboardingDate(date)}
                    placeholderText="Select Onboard Date"
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:00"
                    timeFormat="HH:mm"
                  />
                </div>
              </Form.Group>
            ) : (
              <Form.Group as={Col} controlId="formGridEmpty"></Form.Group>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          disabled={required || (newOnboardingStatus ? !newOnboardingDate : false)}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LocationEditModal
