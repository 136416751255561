import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '@api/backend'
import { IComments, ICommentsRaw, NewCommentData, UpdateCommentData } from '../model/comments.model'
import { displayToast } from '@common/utils/appToast'

export const useAlertComments = (deviceId: string | undefined, alertId: string | undefined) => {
  return useQuery({
    queryKey: ['alert-comments', deviceId, alertId],
    queryFn: () => getAlertComments(deviceId, alertId),
    enabled: !!deviceId && !!alertId,
  })
}

export const useAlertCommentsCreation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postAlertComment,
    onSuccess: (data: ICommentsRaw, { device_id, alert_group_id }) => {
      const newComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        history: [],
      }

      const updatedComments = queryClient.setQueryData(
        ['alert-comments', device_id, alert_group_id],
        (old: IComments[]) => {
          return [newComment, ...old]
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment created successfully',
      })

      return updatedComments
    },
  })
}

export const useAlertCommentsUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateAlertComment,
    onSuccess: (data: ICommentsRaw, { device_id, alert_group_id }) => {
      const updatedComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        history: data.history.map((h) => ({
          comment: h.comment,
          author: h.user_name,
          createdAt: h.date,
          updatedAt: data.valid_to,
        })),
      }

      const updatedComments = queryClient.setQueryData(
        ['alert-comments', device_id, alert_group_id],
        (oldComments: IComments[]) => {
          return oldComments.map((comment: IComments) =>
            comment.id === updatedComment.id ? updatedComment : comment,
          )
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment updated successfully',
      })

      return updatedComments
    },
  })
}

const postAlertComment = async (data: NewCommentData) => {
  const response = await backend.post(`usage_alerts/${data.device_id}/comment`, data)

  return response.data
}

const updateAlertComment = async (data: UpdateCommentData) => {
  const response = await backend.patch(
    `usage_alerts/${data.device_id}/comments?id_type=device_id`,
    data,
  )

  return response.data
}

export async function getAlertComments(deviceId: string | undefined, alertId: string | undefined) {
  const comments = await backend.get(`/usage_alerts/${deviceId}/comment`, {
    params: {
      alert_group_id: alertId,
    },
  })

  comments.data.sort((a: ICommentsRaw, b: ICommentsRaw) => b.id - a.id)

  const alertComments: IComments[] = comments.data.map((comment: ICommentsRaw) => {
    return {
      id: comment.id,
      comment: comment.comment,
      createdAt: comment.date,
      updatedAt: comment.valid_from,
      author: comment.user_name,
      history: comment.history.map((history: ICommentsRaw) => {
        return {
          id: history.id,
          comment: history.comment,
          author: history.user_name,
          createdAt: history.valid_from,
        }
      }),
    }
  })

  return alertComments
}
