import { useAuthState } from '@context/auth/context/auth.context'
import { Navigate, useLocation } from 'react-router-dom'

const RedirectRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()

  const { authenticated } = useAuthState()

  if (!authenticated) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

export default RedirectRoute
