import React, { useState } from 'react'
import { Modal, Form, Button, Col, Row } from 'react-bootstrap'
import Account from '@context/account/model/Account'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'

import './Modal.scss'
import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Info } from 'lucide-react'

import { COUNTRY_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'
import { popoverActive } from './Popovers'

interface IModalProps {
  show: boolean
  account: Account
  onHide: () => void
  onSave: () => void
}

const EditModal: React.FC<IModalProps> = ({ show, account, onHide, onSave }: IModalProps) => {
  const [newAccountName, setNewAccountName] = useState(account.name)
  const [newAddressLine1, setNewAddressLine1] = useState(account.addressLine1 || '')
  const [newAddressLine2, setNewAddressLine2] = useState(account.addressLine2 || '')
  const [newTown, setNewTown] = useState(account.town || '')
  const [newPostcode, setNewPostcode] = useState(account.postcode || '')
  const [newCounty, setNewCounty] = useState(account.county || '')
  const [newCountry, setNewCountry] = useState(account.country)
  const [newPhone, setNewPhone] = useState(account.phone || '')
  const [newAccountAlias, setNewAccountAlias] = useState(account.accountAlias || '')
  const [newIsActive, setNewIsActive] = useState(account.isActive)

  const { createAccount, updateAccount } = useCustomerManagerDispatch()

  function handleSave() {
    const updatedAccount = {
      isActive: newIsActive,
      id: account?.id || '',
      name: newAccountName,
      addressLine1: newAddressLine1,
      addressLine2: newAddressLine2,
      town: newTown,
      postcode: newPostcode,
      county: newCounty,
      country: newCountry,
      phone: newPhone,
      accountAlias: newAccountAlias,
      exSummaryEnabled: false
    }
    if (account.id.length > 0) updateAccount(account, updatedAccount)
    else {
      const reports = {
        report30Enabled: true,
        exSummaryEnabled: true,
        sustainabilityEnabled: true,
        esgEnabled: true,
      }
      createAccount({ ...updatedAccount, ...reports })
    }
    onSave()
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop='static'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='modal-header' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {account ? `Edit Account ${account.name}` : 'New Account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='account-information-form'>
          <Row className='mb-4 fw-bold'>
            <Form.Group
              as={Col}
              controlId="formGridName"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter a name'
                value={newAccountName}
                onChange={(e) => setNewAccountName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            {account.id.length > 0 ? <Form.Group as={Col} controlId='formGridAccountId'>
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                value={account.id}
                disabled
              />
            </Form.Group> : null}
            <Form.Group as={Col} controlId='formLocationOpts' className={'fw-bold'}>
              <Form.Label>Account Options</Form.Label>
              <div>
                <Form.Switch
                  type='checkbox'
                  inline
                  onChange={() => setNewIsActive(!newIsActive)}
                  checked={newIsActive}
                  label={
                    <>
                      Enabled
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverActive}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name='locationAcive'
                  id='inline-location-active'
                />
              </div>
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId='formGridAddressLine1'>
              <Form.Label>
                Address Line 1 <span className='text-danger'>{!newAddressLine1 ? '*' : ''}</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g.   1234 Main St'
                value={newAddressLine1}
                onChange={(e) => setNewAddressLine1(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId='formGridAddressLine2'>
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder='e.g. Apartment, studio, or floor'
                value={newAddressLine2}
                onChange={(e) => setNewAddressLine2(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId='formGridTown'>
              <Form.Label>Town</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. Dublin 9  '
                value={newTown}
                onChange={(e) => setNewTown(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId='formGridPostCode'>
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. D09 P5X4  '
                value={newPostcode}
                onChange={(e) => setNewPostcode(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId='formGridCounty'>
              <Form.Label>County</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. Dublin'
                value={newCounty}
                onChange={(e) => setNewCounty(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId='formGridCountry'>
              <Form.Label>
                Country <span className='text-danger'>{!newCountry ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix='select'
                defaultValue={{ value: newCountry, label: newCountry }}
                onChange={(d: any) => setNewCountry(d.value)}
                options={COUNTRY_OPTIONS.map((country) => {
                  return { value: country, label: country }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row className='mb-4 fw-bold'>
            <Form.Group as={Col} controlId='formGridCounty'>
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. 0845 ...'
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId='formAccountAlias'>
              <Form.Label>Alias</Form.Label>
              <Form.Control
                type='text'
                value={newAccountAlias}
                onChange={(e) => setNewAccountAlias(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn btn-secondary' onClick={onHide}>
          Cancel
        </Button>
        <Button
          disabled={!newAccountName || !newCountry || !newAddressLine1}
          onClick={() => handleSave()}
        >
          {account.id.length > 0 ? 'Update' : 'Activate Account'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal
