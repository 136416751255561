import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import Select from 'react-select'
import { FaBan } from 'react-icons/fa'
import { useDeviceDispatch, useDeviceState } from '@context/device/context/device.context'
import Device, { DeviceIDTypes } from '@context/device/model/device'
import {
  hasPermissions,
  getBatteryIconClass,
  getBatteryPercentage,
  getSignalStatus,
  getBatteryStatus,
  getDeviceStatus,
  pulseOptions,
  roundToTwo,
  // handleStringToBool,
} from '@common/utils/helperFunctions'
import { useAuthState } from '@context/auth/context/auth.context'
import { useAccountState } from '@context/account/context/account.context'
import { useLocationState } from '@context/location/context/location.context'

import {
  SECTOR_TYPES_OPTIONS,
  CURRENCY_OPTIONS,
  FIRMWARE_OPTIONS,
  UPLOAD_FREQ_MINS,
} from '../constants'

import './DeviceModal.scss'
import { useUserState } from '@context/user/context/user.context'

interface IModalProps {
  show: boolean
  deviceData: Device
  onHide: () => void
}

const DeviceModal: React.FC<IModalProps> = ({ show, deviceData, onHide }: IModalProps) => {
  const { accounts } = useAccountState()
  const { locations } = useLocationState()
  const { deviceUpdating, error: deviceError, devices, timezones } = useDeviceState()
  const { permissions } = useAuthState()
  const { userInfo } = useUserState()

  const {
    updateDeviceData,
    updateDevicePulses,
    resetDevice,
    updateFirmwareFilename,
    commissionValves,
    loadDevice,
  } = useDeviceDispatch()

  const [newDeviceName, setNewDeviceName] = useState('')
  const [newDeviceLocation, setNewDeviceLocation] = useState('')
  const [newCost, setNewCost] = useState(2.2)
  const [newCo2Rate, setNewCo2Rate] = useState(10.6)
  const [newCurrency, setNewCurrency] = useState('')
  const [newPulses, setNewPulses] = useState(1)
  const [newFirmware, setNewFirmware] = useState('')
  const [newSectorType, setNewSectorType] = useState('')
  const [newReport30DaySent, setNewReport30DaySent] = useState(false)
  const [newWaterType, setNewWaterType] = useState('')
  const [newDeviceActive, setNewDeviceActive] = useState(false)
  const [newInstallDate, setInstallDate] = useState<Date | null>(null)
  const [newUsageStartDate, setUsageStartDate] = useState<Date | null>(null)
  const [newMonitoringStartDate, setMonitoringStartDate] = useState<Date | null>(null)
  const [newParentDevice, setNewParentDevice] = useState<Device | null>(null)
  const [newOccupants, setNewOccupants] = useState<number>(0)
  const [newTimezone, setNewTimezone] = useState('')
  const [newUploadFreqMins, setNewUploadFreqMins] = useState<string>('')
  const [newMonitoringActive, setNewMonitoringActive] = useState(false)
  const [newAASActive, setNewAASActive] = useState(false)
  const [newClientMeterId, setNewClientMeterId] = useState('')

  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)
  const [didDevicePulsesChange, setDidDevicePulseChange] = useState(false)
  const [didDeviceFirmwareChange, setDidDeviceFirmwareChange] = useState(false)
  const [wasResetSent, setWasResetSent] = useState(false)
  const [wasCommissionValvesSent, setCommissionValvesSent] = useState(false)

  const [wasNewFirmwareSent, setWasNewFirmwareSent] = useState(true)
  const [buttonIdClicked, setButtonIdClicked] = useState('')
  const [tabKey, setTabKey] = useState<string>('device_info_tab')

  const deviceAccount = useMemo(() => accounts.find((account) =>
    account.id === deviceData?.accountId), [accounts, deviceData?.accountId])
  const deviceAdmin = useMemo(() =>
    hasPermissions(permissions, ['UPDATE:DEVICE:PROD:ADMIN']), [permissions]
  )
  const deviceLocation = useMemo(() =>
    locations.find((location) => location.id === deviceData?.deviceLocationId), [locations, deviceData?.deviceLocationId])

  const userUpdateFields = hasPermissions(permissions, ['UPDATE:DEVICE:PROD:USER']) && userInfo.adminCustomerLocationIds.includes(deviceData.deviceLocationId) || userInfo.adminAccountIds.includes(deviceData.accountId)
  let required =
    newDeviceName.length === 0 || newCost === 0 || isNaN(newCost) || newCurrency.length < 3

  useEffect(() => {
      setNewDeviceName(deviceData.deviceSettings.deviceName)
      setNewDeviceLocation(deviceData.deviceSettings.location || '')
      setNewCost(Number(deviceData.deviceSettings.cost) || 2.2)
      setNewCo2Rate(roundToTwo(Number(deviceData.deviceSettings.co2Rate)) || 10.6)
      setNewCurrency(deviceData.deviceSettings.currency)
      setNewPulses(deviceData.deviceSettings.pulsesPerLiter)
      setWasResetSent(false)
      setWasNewFirmwareSent(true)
      setCommissionValvesSent(false)
      setNewSectorType(deviceData.deviceSettings.sectorType)
      setNewReport30DaySent(deviceData.deviceSettings.report30Day || false)
      setNewWaterType(deviceData.deviceSettings.type)
      setNewDeviceActive(deviceData.deviceSettings.active)
      setInstallDate(moment(deviceData.deviceSettings.installDate).toDate()) // Value is currently UTC
      setUsageStartDate(moment(deviceData.deviceSettings.usageStartDate).toDate())
      setMonitoringStartDate(moment(deviceData.deviceSettings.monitoringStartDate).toDate())
      setNewParentDevice(
        devices.find((device) => device.dlId === deviceData.deviceSettings.masterDeviceIdRef) ||
          null,
      )
      setNewOccupants(deviceData.deviceSettings.occupants || 0)
      setNewTimezone(deviceData.deviceSettings.timezone)
      setNewUploadFreqMins(deviceData.deviceSettings.uploadFreqMins)
      setNewMonitoringActive(deviceData.deviceSettings.monitoringEnabled)
      setNewAASActive(deviceData.deviceSettings.aasEnabled)
      setNewClientMeterId(deviceData.deviceSettings.clientMeterId)

  }, [deviceData])

  useEffect(() => {
    // if (deviceData && deviceData.deviceSettings) {
      if (
        deviceData.deviceName !== newDeviceName ||
        deviceData.deviceSettings.location !== newDeviceLocation ||
        Number(deviceData.deviceSettings.cost) !== newCost ||
        deviceData.deviceSettings.currency !== newCurrency ||
        deviceData.deviceSettings.sectorType !== newSectorType ||
        deviceData.deviceSettings.report30Day !== newReport30DaySent ||
        deviceData.deviceSettings.pulsesPerLiter !== newPulses ||
        deviceData.deviceSettings.type !== newWaterType ||
        deviceData.deviceSettings.masterDeviceIdRef !==
          (newParentDevice ? newParentDevice?.dlId : null) ||
        deviceData.deviceSettings.installDate !==
          moment(newInstallDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.usageStartDate !==
          moment(newUsageStartDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.monitoringStartDate !==
          moment(newMonitoringStartDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.active !== newDeviceActive ||
        deviceData.deviceSettings.occupants !== newOccupants ||
        deviceData.deviceSettings.timezone !== newTimezone ||
        deviceData.deviceSettings.uploadFreqMins !== newUploadFreqMins ||
        deviceData.deviceSettings.monitoringEnabled !== newMonitoringActive ||
        deviceData.deviceSettings.aasEnabled !== newAASActive ||
        deviceData.deviceSettings.clientMeterId !== newClientMeterId ||
        deviceData.deviceSettings.co2Rate !== newCo2Rate
      ) {
        // Some value has changed
        setDidDeviceInfoChange(true)
      } else {
        setDidDeviceInfoChange(false)
      }
    // }
  }, [
    deviceData,
    newDeviceName,
    newDeviceLocation,
    newCost,
    newCurrency,
    newSectorType,
    newReport30DaySent,
    newWaterType,
    newDeviceActive,
    newInstallDate,
    newUsageStartDate,
    newMonitoringStartDate,
    newParentDevice,
    newOccupants,
    newTimezone,
    newUploadFreqMins,
    newClientMeterId,
    newAASActive,
    newMonitoringActive,
    newCo2Rate
  ])

  useEffect(() => {
    if (buttonIdClicked && !deviceUpdating) {
      setButtonIdClicked('')
    }
  }, [deviceUpdating, deviceError])

  useEffect(() => {
    if (deviceData && Object.keys(deviceData).length > 0) {
      if (deviceData.deviceSettings.pulsesPerLiter !== newPulses) {
        setDidDevicePulseChange(true)
      } else {
        setDidDevicePulseChange(false)
      }
    }
  }, [newPulses, deviceData])

  useEffect(() => {
    if (Object.keys(deviceData).length > 0) {
      if (newFirmware && deviceData.firmware !== newFirmware) {
        setDidDeviceFirmwareChange(true)
        setWasNewFirmwareSent(false)
      } else {
        setDidDeviceFirmwareChange(false)
      }
    }
  }, [newFirmware, deviceData])

  const handleOnUpdateDeviceInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!deviceData) return

    setButtonIdClicked(e.currentTarget.id)
    const updatedDevice = { ...deviceData }
    updatedDevice.deviceName = newDeviceName
    updatedDevice.deviceSettings.location = newDeviceLocation
    updatedDevice.deviceSettings.currency = newCurrency
    updatedDevice.deviceSettings.cost = newCost
    updatedDevice.deviceSettings.sectorType = newSectorType
    updatedDevice.deviceSettings.report30Day = newReport30DaySent
    updatedDevice.deviceSettings.active = newDeviceActive
    updatedDevice.deviceSettings.type = newWaterType
    updatedDevice.deviceSettings.hot = newWaterType === 'Hot'
    updatedDevice.deviceSettings.masterDeviceIdRef = newParentDevice?.dlId
    updatedDevice.deviceSettings.occupants = newOccupants
    updatedDevice.deviceSettings.timezone = newTimezone
    updatedDevice.deviceSettings.installDate = moment(newInstallDate).format('YYYY-MM-DDTHH:00:00')
    updatedDevice.deviceSettings.usageStartDate =
      moment(newUsageStartDate).format('YYYY-MM-DDTHH:00:00')
    updatedDevice.deviceSettings.monitoringStartDate =
      moment(newMonitoringStartDate).format('YYYY-MM-DDTHH:00:00')
    updatedDevice.deviceSettings.uploadFreqMins = newUploadFreqMins
    updatedDevice.deviceSettings.monitoringEnabled = newMonitoringActive
    updatedDevice.deviceSettings.aasEnabled = newAASActive
    updatedDevice.deviceSettings.co2Rate = newCo2Rate
    updatedDevice.deviceSettings.clientMeterId = newClientMeterId
    // await updateDeviceData(updatedDevice)
    if (deviceData?.dUUID) {
      await updateDeviceData(deviceData?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }
  }

  const handleOnUpdateDevicePulses = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      // if (deviceData?.dUUID) {
        await updateDevicePulses(deviceData.dUUID, DeviceIDTypes.dUUID, newPulses)
      // }
    }
  }

  const handleOnForceUpdateDeviceFirmware = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      // if (deviceData?.dUUID) {
        await updateFirmwareFilename(deviceData.dUUID, DeviceIDTypes.dUUID, newFirmware)
      // }
      setWasNewFirmwareSent(true)
    }
  }

  const handleOnDeviceReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      // if (deviceData?.dUUID) {
        resetDevice(deviceData.dUUID, DeviceIDTypes.dUUID)
      // }

      setWasResetSent(true)
    }
  }

  const handleOnCommissionValves = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      const { deviceId } = deviceData
      commissionValves(deviceId)
      setCommissionValvesSent(true)
    }
  }

  const getPossibleParents = () => {
    return devices.filter(
      (device) =>
        device.deviceLocationId === deviceData?.deviceLocationId &&
        device.dUUID !== deviceData.dUUID &&
        !deviceData.childDeviceIds?.includes(device.deviceSettings.id),
    )
  }

  const handleRefreshData = () => {
    // if (deviceData?.dUUID) {
      loadDevice(deviceData.dUUID, DeviceIDTypes.dUUID)
    // }
  }

  const MVNOLink = (iccid: string, mvno: string) => {
    if (mvno === 'Onomondo') {
      return `https://app.onomondo.com/sims/${iccid.slice(10, 19,)}?tab=usage`
    } else if (mvno === 'Simpl') {
      return `https://app.esimpl.io/manage/inventory/detail?id=${iccid}`
    } else {
      return ''
    }
  }

  function renderDeviceStatus(device: Device) {
    const deviceStatus = getDeviceStatus(
      device,
      hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW']),
    )

    if (deviceStatus === 'Offline' && hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW'])) {
      return (
        <>
          Offline
          <small style={{ fontSize: '0.7em' }}>{` (last seen: ${moment(
            device.lastOnline,
          ).fromNow()})`}</small>
        </>
      )
    }

    return deviceStatus
  }

  if (!deviceData) return null
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='device-information-modal'
    >
      <Modal.Header className='modal-header' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{deviceData.deviceName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {required ? <span className='red'>* Required!!</span> : ''}
        <Row>
          <Col>
            {/*<div>*/}
            {/*  <h4>{deviceData.deviceName}</h4>*/}
            {/*</div>*/}
          </Col>
          <Col>
            <div>
              <UpdateButton
                buttonText={'Refresh'}
                id={'update-device-info'}
                disabled={false}
                loading={false}
                onClick={handleRefreshData}
                classProps={'float-end'}
              />
            </div>
          </Col>
        </Row>
        {/*<hr />*/}

        <Tabs id='controlled-tab' activeKey={tabKey} onSelect={(k: any) => setTabKey(k)}>
          <Tab eventKey='device_info_tab' title='Info'>
            <Form className='device-information-form'>
              <Row className='mb-2 mt-4'>
                <Form.Group as={Col} controlId='formGridName'>
                  <Form.Label>Device Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter a name'
                    value={newDeviceName}
                    onChange={(e) => setNewDeviceName(e.target.value)}
                    disabled={!deviceAdmin}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridName'>
                  <Form.Label className={'fw-bold'}>Location Reference</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Pole B12'
                    value={newDeviceLocation}
                    onChange={(e) => setNewDeviceLocation(e.target.value)}
                    disabled={!deviceAdmin}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridId'>
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    defaultValue={deviceData.deviceId}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridUUID'>
                  <Form.Label className={'fw-bold'}>UUID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.dUUID}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridDlId'>
                  <Form.Label>Link ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.dlId}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridco2'>
                  <Form.Label className={'fw-bold'}>Co<sub>2 </sub> rate</Form.Label>
                  <Form.Control
                    type='number'
                    min='0.01'
                    value={newCo2Rate}
                    onChange={(e) =>
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : setNewCo2Rate(parseFloat(e.target.value))
                    }
                    disabled={!deviceAdmin && !userUpdateFields}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridClienMeterId'>
                  <Form.Label className={'fw-bold'}>Client Meter ID</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder={''}
                    value={newClientMeterId}
                    onChange={(e) => setNewClientMeterId(e.target.value)}
                    disabled={!deviceAdmin && !userUpdateFields}
                  />
                </Form.Group>
              </Row>
              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridCost'>
                  <Form.Label className={'fw-bold'}>
                    Cost per {deviceData.deviceSettings.uom}&#179;
                    <span className='red'>{newCost === 0 || isNaN(newCost) ? '*' : ''}</span>
                  </Form.Label>
                  <Form.Control
                    type='number'
                    min='0.01'
                    placeholder='e.g. 1.93'
                    value={newCost}
                    onChange={(e) =>
                      isNaN(parseFloat(e.target.value)) ? 0 : setNewCost(parseFloat(e.target.value))
                    }
                    disabled={!deviceAdmin && !userUpdateFields}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridCurrency'>
                  <Form.Label className={'fw-bold'}>
                    Currency <span className='red'>{newCurrency.length !== 3 ? '*' : ''}</span>
                  </Form.Label>
                  <Select
                    classNamePrefix='select'
                    defaultValue={{
                      value: deviceData.deviceSettings.currency,
                      label: deviceData.deviceSettings.currency,
                    }}
                    onChange={(d: any) => setNewCurrency(d.value)}
                    options={CURRENCY_OPTIONS.map((currency) => {
                      return { value: currency, label: currency }
                    })}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                    isDisabled={!deviceAdmin && !userUpdateFields}
                  />
                </Form.Group>
              </Row>
              <Row className='mb-2'>
                <Form.Group
                  as={Col}
                  controlId='formDeviceActive'
                  className='d-flex align-items-center mt-4'
                >
                  <Form.Switch
                    type='checkbox'
                    inline
                    onChange={() => setNewDeviceActive(!newDeviceActive)}
                    checked={newDeviceActive}
                    label='Device Active'
                    id={`inline-device-active`}
                    disabled={!deviceAdmin}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formTimezone'>
                  <Form.Label className={'fw-bold'}>Timezone</Form.Label>
                  <Select
                    classNamePrefix='select'
                    defaultValue={{
                      value: deviceData.deviceSettings.timezone,
                      label: deviceData.deviceSettings.timezone,
                    }}
                    onChange={(d: any) => setNewTimezone(d.value)}
                    options={timezones.map((timezone) => {
                      return { value: timezone.name, label: timezone.name }
                    })}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                    isDisabled={!deviceAdmin}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridOccupants'>
                  <Form.Label className={'fw-bold'}>Occupants</Form.Label>
                  <Form.Control
                    type='number'
                    value={newOccupants}
                    min={0}
                    onChange={(e) => setNewOccupants(parseInt(e.target.value))}
                    disabled={!deviceAdmin && !userUpdateFields}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-2 mt-1'>
                {getPossibleParents().length > 0 && deviceAdmin && (
                  <Form.Group as={Col} controlId='formWaterType'>
                    <Form.Label className={'fw-bold'}>Main Meter Device</Form.Label>
                    <Select
                      classNamePrefix='select'
                      onChange={(d: any) => setNewParentDevice(d)}
                      options={getPossibleParents()}
                      placeholder='Select..'
                      getOptionValue={(option: any) => option.dlId}
                      getOptionLabel={(option: any) => `${option.deviceName}`}
                      isClearable={true}
                      value={newParentDevice}
                      isDisabled={!deviceAdmin && !userUpdateFields}
                    />
                  </Form.Group>
                )}

                {deviceData.childDeviceIds?.length > 0 ? (
                  <Form.Group as={Col} controlId='formWaterType'>
                    <Form.Label className={'fw-bold'}>
                      {`Sub meter(s) (${deviceData.childDeviceIds.length} total)`}
                    </Form.Label>
                    <Form.Control
                      value={devices
                        .filter((device) =>
                          deviceData.childDeviceIds.includes(device.deviceSettings.id || 0),
                        )
                        .map((child) => child.deviceName)
                        .join(', ')}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} controlId='formGridNetInfo'></Form.Group>
                )}
              </Row>
              <UpdateButton
                id={'update-device-info'}
                buttonText={'Update'}
                disabled={!didDeviceInfoChange || deviceUpdating}
                loading={deviceUpdating && buttonIdClicked === 'update-device-pulses'}
                onClick={handleOnUpdateDeviceInfo}
              />
            </Form>

            {hasPermissions(permissions, ['UPDATE:DEVICE:PULSE']) ? (
              <>
                <hr />
                <Form className='device-information-form'>
                  <Row className='mb-2'>
                    <Form.Group as={Col} controlId='exampleForm.ControlSelect1'>
                      <Form.Label className={'fw-bold'}>Pulses</Form.Label>
                      <Select
                        classNamePrefix='select'
                        value={{
                          value: newPulses.toString(),
                          label:
                            newPulses > 1
                              ? `${newPulses} - ${newPulses} Pulses/${deviceData.deviceSettings.uom}`
                              : `${newPulses} - ${1 / newPulses} ${deviceData.deviceSettings.uom}/pulse`,
                        }}
                        onChange={(e: any) => setNewPulses(Number(e.value))}
                        options={pulseOptions(deviceData.deviceSettings.uom)}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => `${option.label}`}
                        isClearable={false}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId='exampleForm.ControlSelect1'></Form.Group>
                  </Row>
                  <UpdateButton
                    id={'update-device-info'}
                    buttonText={'Update'}
                    disabled={!didDevicePulsesChange || deviceUpdating}
                    loading={deviceUpdating && buttonIdClicked === 'update-device-pulses'}
                    onClick={handleOnUpdateDevicePulses}
                  />
                </Form>
              </>
            ) : null}
          </Tab>
          {deviceAdmin && deviceData.deviceVendor === 'SMARTFLOW'?
            <Tab eventKey='device_commands_tab' title='Device'>
              {deviceData.deviceVendor === 'SMARTFLOW' &&
              hasPermissions(permissions, ['DEVICE:FIRMWARE:UPDATE']) ? (
                <>
                  <hr />
                  <Form className='device-commands-form'>
                    <Row className='mb-2'>
                      <Form.Group as={Col} controlId='fwForm.ControlSelect'>
                        <Form.Label className={'fw-bold'}>Firmware Update</Form.Label>
                        <Select
                          classNamePrefix='select'
                          onChange={(d: any) => setNewFirmware(d.value)}
                          options={FIRMWARE_OPTIONS.map((fw) => {
                            return { value: fw, label: fw }
                          })}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => `${option.label}`}
                          isClearable={false}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId='formCurrentBinfile'>
                        <Form.Label className={'fw-bold'}>Current Bin File</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder=''
                          value={deviceData.binFileName}
                          disabled
                        />
                      </Form.Group>
                    </Row>
                    <UpdateButton
                      buttonText={'Send Update'}
                      id={'update-device-firmware'}
                      disabled={!didDeviceFirmwareChange && wasNewFirmwareSent}
                      loading={deviceUpdating && buttonIdClicked === 'update-device-firmware'}
                      onClick={handleOnForceUpdateDeviceFirmware}
                    />
                    <div className='side-note'>
                      * Remember to Reset the device (at least 1 minute) after this operation
                    </div>
                  </Form>
                </>
              ) : null}

              {deviceData.deviceVendor === 'SMARTFLOW' &&
              hasPermissions(permissions, ['DEVICE:RESET']) ? (
                <>
                  <hr />
                  <Form className='device-reset-form'>
                    <Row>
                      <Form.Label className={'fw-bold'}>Reset</Form.Label>
                    </Row>
                    <UpdateButton
                      buttonText={'Reset Device'}
                      id={'reset-device'}
                      disabled={wasResetSent}
                      loading={deviceUpdating && buttonIdClicked === 'reset-device'}
                      onClick={handleOnDeviceReset}
                    />
                  </Form>
                </>
              ) : null}
              {deviceData.deviceVendor === 'SMARTFLOW' && hasPermissions(permissions, ['DEVICE:VALVE:COMM']) ? (
                <>
                  <hr />
                  <Form className='device-comm-valves-form'>
                    <Row>
                      <Form.Label className={'fw-bold'}>Commission Valves</Form.Label>
                    </Row>
                    <UpdateButton
                      buttonText={'Commission Valves'}
                      id={'commission-valves'}
                      disabled={wasCommissionValvesSent}
                      loading={deviceUpdating && buttonIdClicked === 'commission-valves'}
                      onClick={handleOnCommissionValves}
                      loadingText={'Commissioning...'}
                    />
                  </Form>
                </>
              ) : null}
              <hr />
            </Tab> : null}
          {deviceAdmin ?
            <Tab eventKey='device_monitoring_tab' title='Monitoring'>
              <Form className='device-monitoring-form'>
                <Row className='mt-4 mb-2'>
                  <Form.Group as={Col} controlId='formInstallDate'>
                    <Form.Label className={'fw-bold'}>Install Date</Form.Label>
                    <div className='date-picker'>
                      <DatePicker
                        selected={newInstallDate}
                        onChange={(date: Date | null) => setInstallDate(date)}
                        placeholderText='Select Install date'
                        showTimeSelect
                        dateFormat='dd/MM/yyyy HH:00'
                        timeFormat='HH:mm'
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} controlId='formUsageDate'>
                    <Form.Label className={'fw-bold'}>Usage Start Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={newUsageStartDate}
                        onChange={(date: Date | null) => setUsageStartDate(date)}
                        placeholderText='Select Usage Start Date'
                        showTimeSelect
                        dateFormat='dd/MM/yyyy HH:00'
                        timeFormat='HH:mm'
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row className='mb-2'>
                  <Form.Group as={Col} controlId='formMonitoringDate'>
                    <Form.Label className={'fw-bold'}>Monitoring Start Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={newMonitoringStartDate}
                        onChange={(date: Date | null) => setMonitoringStartDate(date)}
                        placeholderText='Select Monitoring Start Date'
                        showTimeSelect
                        dateFormat='dd/MM/yyyy HH:00'
                        timeFormat='HH:mm'
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} controlId='formGridUploadFreq'>
                    <Form.Label className={'fw-bold'}>
                      Upload Frequency (hours)
                      <span className='red'>
                        {newUploadFreqMins && newUploadFreqMins.length === 0 ? '*' : ''}
                      </span>
                    </Form.Label>
                    <Select
                      classNamePrefix='select'
                      defaultValue={{
                        value: deviceData.deviceSettings.uploadFreqMins,
                        label: deviceData.deviceSettings.uploadFreqMins,
                      }}
                      value={{
                        value: newUploadFreqMins,
                        label: newUploadFreqMins,
                      }}
                      onChange={(d: any) => setNewUploadFreqMins(d.value)}
                      options={UPLOAD_FREQ_MINS.map((freq) => {
                        return { value: freq, label: freq }
                      })}
                      getOptionValue={(option) => `${option.value}`}
                      getOptionLabel={(option) => `${option.label / 60}`}
                      isClearable={false}
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2'>
                  <Form.Group as={Col} controlId='formSectorType'>
                    <Form.Label className={'fw-bold'}>
                      Sector <span className='red'>{!newSectorType ? '*' : ''}</span>
                    </Form.Label>
                    <Select
                      classNamePrefix='select'
                      defaultValue={{
                        value: deviceData.deviceSettings.sectorType,
                        label: deviceData.deviceSettings.sectorType,
                      }}
                      onChange={(d: any) => setNewSectorType(d.value)}
                      options={SECTOR_TYPES_OPTIONS.map((sector) => {
                        return { value: sector, label: sector }
                      })}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => `${option.label}`}
                      isClearable={false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='formWaterType'>
                    <Form.Label className={'fw-bold'}>Water Type</Form.Label>
                    <Select
                      classNamePrefix='select'
                      defaultValue={{
                        value: deviceData.deviceSettings.type,
                        label: deviceData.deviceSettings.type,
                      }}
                      onChange={(d: any) => setNewWaterType(d.value)}
                      options={['Hot', 'Cold'].map((sector) => {
                        return { value: sector, label: sector }
                      })}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => `${option.label}`}
                      isClearable={false}
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-4 mt-4'>
                  <Form.Group
                    as={Col}
                    controlId='formGridMonSettings'
                  >
                    <Form.Switch
                      inline
                      onChange={() => setNewMonitoringActive(!newMonitoringActive)}
                      checked={newMonitoringActive}
                      label='Monitoring Active'
                      id={`inline-monitoring-active`}
                      className={'fw-bold'}
                    />
                    <Form.Switch
                      inline
                      onChange={() => setNewAASActive(!newAASActive)}
                      checked={newAASActive}
                      label='Auto Alerts Active'
                      id={`inline-aas-active`}
                      className={'ml-lg-3 fw-bold'}
                    />
                    <Form.Switch
                      inline
                      onChange={() => setNewReport30DaySent(!newReport30DaySent)}
                      checked={newReport30DaySent}
                      label='30 Day Report Completed'
                      id={`inline-30-day-report-completed`}
                      className={'ml-lg-3 fw-bold'}
                    />
                  </Form.Group>
                </Row>
                <UpdateButton
                  id={'update-device-info'}
                  buttonText={'Update'}
                  disabled={!didDeviceInfoChange || deviceUpdating}
                  loading={deviceUpdating && buttonIdClicked === 'update-device-pulses'}
                  onClick={handleOnUpdateDeviceInfo}
                />
              </Form>
            </Tab> : null
          }
          <Tab eventKey='device_network_tab' title='Network'>
            <Form className='device-network-form mt-4 mb-4'>
              {deviceAdmin ?
              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridICCID'>
                  <Form.Label className={'fw-bold'}>
                    ICCID
                    {deviceAdmin && deviceData.ICCID && deviceData.mvno ? (
                      <span className={'ml-2'}>
                        <Link
                          target='_blank'
                          to={MVNOLink(deviceData.ICCID, deviceData.mvno)}
                        >
                          (Go to SIM)
                        </Link>
                      </span>
                    ) : null}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.ICCID}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridMVNO'>
                  <Form.Label className={'fw-bold'}>MVNO</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.mvno}
                    disabled
                  />
                  </Form.Group>
              </Row>
                : null}
              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridNetName'>
                  <Form.Label className={'fw-bold'}>Network Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.networkName}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='formGridCSQ'>
                  <Form.Label className={'fw-bold'}>CSQ</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.signal}
                    disabled
                  />
                </Form.Group>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey='device_status_tab' title='Status'>
            <Form className='device-network-form'>
              <Row className='mb-2 mt-4'>
                <Form.Group as={Col} controlId='formGridICCID'>
                  <Form.Label className={'fw-bold'}>Battery %</Form.Label>
                  <div className='battery-container'>
                    <span
                      className={getBatteryIconClass(
                        getBatteryPercentage(deviceData.batteryLevel, deviceData.deviceVendor),
                      )}
                    />
                    <span className='battery-level ml-2'>
                      {getBatteryPercentage(deviceData.batteryLevel, deviceData.deviceVendor)}%
                      (Int)
                    </span>
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId='formGridNetName'>
                  <Form.Label className={'fw-bold'}>Power</Form.Label>
                  {deviceData.batteryStatus ? (
                    <Form.Control
                      type='text'
                      placeholder=''
                      value={getBatteryStatus(deviceData)}
                      disabled
                    />
                  ) : (
                    <div>
                      <FaBan />
                    </div>
                  )}
                </Form.Group>
              </Row>
              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridMVNO'>
                  <Form.Label className={'fw-bold'}>Signal</Form.Label>
                  <div className='signal-container'>
                    <span>
                      {deviceData.signal ? getSignalStatus(deviceData.signal) : <FaBan />}
                    </span>
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId='formGridCSQ'>
                  <Form.Label className={'fw-bold'}>State</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={deviceData.deviceSettings.active ? 'Active' : 'Inactive'}
                    disabled
                  />
                </Form.Group>
              </Row>
              <Row className='mb-2'>
                <Form.Group as={Col} controlId='formGridCSQ'>
                  <Form.Label className={'fw-bold'}>Status</Form.Label>
                  {deviceData.deviceVendor === 'SMARTFLOW' ||
                  deviceData.deviceVendor === 'METASPHERE' ||
                  deviceData.deviceVendor === 'PLUM' ? (
                    <div>{renderDeviceStatus(deviceData)}</div>
                  ) : (
                    <div>
                      <FaBan />
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Form>
          </Tab>
          {deviceAdmin ?
            <Tab eventKey='device_location_tab' title='Location'>
              <Form className='device-location-form'>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Location Name</Form.Label>
                    <Form.Control
                      value={deviceLocation?.name}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Address Line 1</Form.Label>
                    <Form.Control
                      value={deviceLocation?.addressLine1 || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Address Line 2</Form.Label>
                    <Form.Control
                      value={deviceLocation?.addressLine2 || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Town</Form.Label>
                    <Form.Control
                      value={deviceLocation?.town || undefined}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Postcode</Form.Label>
                    <Form.Control
                      value={deviceLocation?.postcode || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>County</Form.Label>
                    <Form.Control
                      value={deviceLocation?.county || undefined}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Location Country</Form.Label>
                    <Form.Control
                      value={deviceLocation?.country || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Tab> : null}
          {deviceAdmin ?
            <Tab eventKey='device_account_tab' title='Account'>
              <Form className='device-account-form'>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Account Name</Form.Label>
                    <Form.Control
                      value={deviceAccount?.name}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className='mb-2 mt-4'>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Address</Form.Label>
                    <Form.Control
                      value={deviceAccount?.addressLine1 || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Town</Form.Label>
                    <Form.Control
                      value={deviceAccount?.town || undefined}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Postcode</Form.Label>
                    <Form.Control
                      value={deviceAccount?.postcode || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>County</Form.Label>
                    <Form.Control
                      value={deviceAccount?.county || undefined}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className={'fw-bold'}>Country</Form.Label>
                    <Form.Control
                      value={deviceAccount?.country || undefined}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Tab> : null}
          </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button id='close-modal' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

interface IUpdateButtonProps {
  buttonText: string
  id: string
  disabled: boolean
  loading: boolean
  onClick(e: React.MouseEvent<HTMLButtonElement>): void
  loadingText?: string
  classProps?: string
}

const UpdateButton = ({ buttonText, id, disabled, loading, onClick, loadingText, classProps}: IUpdateButtonProps) => {
 return (
   <Button
    id={id}
    variant='primary'
    type='button'
    disabled={disabled}
    onClick={onClick}
    className={classProps}
   >
    {loading ? (
      <>
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        />
        <span>{loadingText || 'Loading...'}</span>
      </>
    ) : (
      <>{buttonText}</>
    )}
  </Button>
 )
}

export default DeviceModal
