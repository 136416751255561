import backend from '@api/backend'
import { IComments, ICommentsRaw, NewAccountCommentData, UpdateAccountComment } from '../model/comments.model'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { displayToast } from '@common/utils/appToast'

export const useAccountComments = (accountId: string, isAdmin: boolean) => {
  return useQuery({
    queryKey: ['account-comments', accountId],
    queryFn: () => getAccountComments(accountId),
    enabled: isAdmin,
    staleTime: 1000 * 60 * 15, // 15 minutes,
  })
}

export const useAccountCommentsCreation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postAccountComment,
    onSuccess: (data: ICommentsRaw, { accountId }) => {
      const newComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: [],
      }

      const updatedComments = queryClient.setQueryData(
        ['account-comments', accountId],
        (old: IComments[]) => {
          return [newComment, ...old]
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment created successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })
}

export const useAccountCommentsUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateAccountComment,
    onSuccess: (data: ICommentsRaw, { accountId }) => {
      const updatedComment = {
        id: data.id,
        comment: data.comment,
        createdAt: data.date,
        updatedAt: data.valid_from,
        author: data.user_name,
        department: data.department,
        category: data.category,
        history: data.history?.map((h) => ({
          comment: h.comment,
          author: h.user_name,
          createdAt: h.valid_from,
          updatedAt: data.valid_to,
        })),
      }

      const updatedComments = queryClient.setQueryData(
        ['account-comments', accountId],
        (oldComments: IComments[]) => {
          return oldComments.map((comment: IComments) =>
            comment.id === updatedComment.id ? updatedComment : comment,
          )
        },
      )

      displayToast({
        type: 'success',
        message: 'Comment updated successfully',
      })

      return updatedComments
    },
    onError: () => {
      displayToast({
        type: 'error',
        message: 'Something went wrong',
      })
    },
  })
}

export async function getAccountComments(accountId: string) {
  const response = await backend.get(`/accounts/${accountId}/comments`, {
    params: { history: true },
  })

  response.data.sort((a: ICommentsRaw, b: ICommentsRaw) => b.id - a.id)

  const accountComments: IComments[] = response.data.map((comment: ICommentsRaw) => {
    return {
      id: comment.id,
      comment: comment.comment,
      createdAt: comment.date,
      updatedAt: comment.valid_from,
      author: comment.user_name,
      department: comment.department,
      category: comment.category,
      history: comment.history?.map((history: any) => {
        return {
          id: history.id,
          comment: history.comment,
          author: history.user_name,
          createdAt: history.valid_from,
        }
      }),
    }
  })

  return accountComments
}

async function postAccountComment(data: NewAccountCommentData) {
  const response = await backend.post(`accounts/${data.accountId}/comments`, data)

  return response.data
}

async function updateAccountComment(data: UpdateAccountComment) {
  const response = await backend.patch(`accounts/${data.accountId}/comments`, data)

  return response.data
}
